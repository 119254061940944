import React from "react";

export const IceMenu = () => {
  return (
    <div id="icemenu" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Ice Menu</h2>
        </div>
        <div className="image-container">
          <img src="img/ice/menu.jpeg" alt="" />
        </div>
      </div>
    </div>
  );
};
