import React from "react";

export const CoffeeMenu = () => {
  return (
    <div id="coffeemenu" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Coffee Menu</h2>
        </div>
        <div className="image-container">
          <img src="img/ice/coffeemenu.jpeg" alt="" />
        </div>
      </div>
    </div>
  );
};
